import React from "react"
import BottomStart from "../../images/proposito/BottomStart.png"

import { SECTION_MICROCERVECERIA, SECTION_EXPERIENCIA } from "./Header"

const Bottom = ({ section, handler }) => {
  
  const titulo =
    section === SECTION_MICROCERVECERIA ? "Las experiencias" : "La microcervecería"

  const targetSection =
    section === SECTION_MICROCERVECERIA ? SECTION_EXPERIENCIA : SECTION_MICROCERVECERIA
  
    return (
    <div id="bottomPropContainer">
      <img  alt="" src={BottomStart} className="imgBottomStart" />
      <div className="infoContainer">
        <div className="bottomText1">{"No te pierdas"}</div>
        <div className="bottomText2">{titulo}</div>
        <div className="bottomButton" onClick={() => handler(targetSection)}>
          Ver mas
        </div>
      </div>
    </div>
  )
}

export default Bottom

import React, {useState, useEffect} from "react"
import underline from "../../images/proposito/underline.png"
import underline2 from "../../images/proposito/underline2.png"
import leftFixed from "../../images/microbeer/leftFixedPropMicro.png"
import propositoImg1 from "../../images/proposito/NuestroProposito1.png"
import propositoImg2 from "../../images/proposito/NuestroProposito2.png"
import microImg3 from "../../images/microbeer/microImg3.png"
import microImg4 from "../../images/microbeer/microImg4.png"
import point1 from "../../images/microbeer/point1.png"
import line from "../../images/microbeer/line.png"
import point2 from "../../images/microbeer/point2.png"
import gridimages from "../../images/microbeer/gridimages.png"
import carrouselImage2 from "../../images/proposito/carrousel2.png"
import carrouselImage3 from "../../images/proposito/carrousel3.png"
import arrowToLeft from "../../images/proposito/arrowToLeft.png"
import arrowToDown from "../../images/microbeer/arrowToDown.png"
import arrowMobile from "../../images/proposito/arrowPropositoMobile.png"
import greenPoint from "../../images/proposito/greenPoint.png"
import carrouselImage1Mobile from "../../images/proposito/NuestroProposito1Mobile.png"
import FadeIn from './utilities/FadeIn'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const MicrocerveceriaSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const carrouselImages = [
    {
      foto: carrouselImage1Mobile,
    },
    {
      foto: carrouselImage2,
    },
    {
      foto: carrouselImage3,
    },
  ]

  return (
    <>
    <div id="nuestroPropContainer">
      <img  alt="" className="fixedPropo" src={leftFixed} />

      <div className="causaStory odd">
        <div className="causaStoryDiv left propositoImage">
          <div
            className="causaImagenContainer"

          >
            {/* <Parallax src={propositoImg1} position={'left'} end={false} /> */}
            <FadeIn img={propositoImg1} position={'left'} end={false} />
          </div>
        </div>
        <div className="causaStoryDiv right propositoText">
            <h1 className="microtitle ">
              EL KM 24.7 DE BARILOCHE
            </h1>
            <div className="propositoTitulo">
            <div>
              <img  alt="" src={underline} />
            </div>
            <img  alt="" className="arrowPropoMobile" src={arrowMobile} />
          </div>
          <div className="causatextwidth">
            <p className="causaTextDesktop">
              <br/>
              <br/>
              Nuestro objetivo es invitarte a vivir la <br/><span className="highlighted2">mejor experiencia cervecera </span> del mundo desde la Patagonia, es por eso que las puertas de nuestra <mark className="fontBlack">Microcervecería</mark> están abiertas todos los días para que nuestros invitados conozcan la dedicación con la que hacemos nuestras  cervezas. <br/> Explorando nuestro hogar te podés sorprender con <mark className="fontBlack">talleres de home-brewing, noches de bandas en vivo y tours cerveceros</mark>, todo esto en un entorno natural que respetamos con nuestra planta de efluentes con tecnología de tratamiento biológico, única en la Argentina.
              <br/>
              <br/>
            </p>

            <p className="causaTextMobile">
              Nuestra Microcervecería es el lugar del mundo donde mas fuerte late el corazón de Patagonia. Las puertas están abiertas para invitarte a vivir la <br/><span className="highlighted2"> mejor experiencia cervecera</span> ,viendo la dedicación con la que hacemos nuestras cervezas.
              <br />
              <br />
              Explorando nuestro hogar te podes sorprender con noches de bandas en <mark className="fontBlack">vivo, tour cerveceros y talleres de home brewing</mark>, todo esto en un entorno natural que respetamos junto a nuestra Planta de efluentes con tecnología de tratamiento biológico, única en la Argentina.
              <span className="highlighted2">
              </span>
            </p>
          </div>
        </div>
        <img  alt="" className="arrowPropMicro" src={arrowToLeft} />
      </div>

      <div className="displaymobile">
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer marginPicture">
              <FadeIn img={carrouselImage1Mobile} position={"left"} end={false} />
            </div>
          </div>
        </div>

      <img  alt="" className="greenPoint" src={greenPoint} />

      <div className="causaStory odd noMargin">
        <div className="causaStoryDiv left propositoText">
          <div className="causatextwidth">
            <p className="causaTextDesktop">
              <br />
              <br />
              Al entrar, la mirada va directo hacia el Lago Moreno y las montañas que lo resguardan. Luego se siente el aroma de la <mark className="fontBlack">cerveza cocinándose</mark>, que invita a saborear nuestras variedades y descubrir la gastronomía patagónica.<br/>
              En el <span className="highlighted2">Salón</span> te esperamos con una carta que incluye entradas, principales, sándwiches y postres, con <mark className="fontBlack">atención en mesa.</mark> Mientras tanto, en el <br/><span className="highlighted2">Jardín Cervecero</span>, te esperamos con una propuesta más descontracturada, con puntos de venta <mark className="fontBlack">autoservicio</mark> y propuestas gastronómicas que salen directo de las brasas, rodeado de naturaleza y con acceso a los miradores del Lago Moreno.
              <br />
              <br />
            </p>

            <p className="causaTextMobile">
            Al entrar, la mirada va directo hacia el Lago Moreno y las montañas que lo resguardan. Luego se siente el <span className="highlighted2">aroma de la cerveza </span> cocinándose, que invita a saborear nuestras variedades y descubrir la gastronomía patagónica.
              
            </p>
          </div>
        </div>
        <div className="causaStoryDiv right propositoImage">
          <div
            className="causaImagenContainer"
          >
            {/* <Parallax src={propositoImg2} position={'right'} end={false}/> */}
            <FadeIn img={propositoImg2} position={'right'} end={false} />

          </div>
        </div>
        <img  alt="" src={arrowToDown} className="arrowProp" />
      </div>
 </div>
    <div>
      <div className="reservationTitle">
        <h1>
          Hacé tu reserva acá 
        </h1>
        <div className="reservationButton">
          <button  onClick={() => window.open('https://patagonia.meitre.com/', '_blank')} className="reservationButton1">
            RESERVA salón 
          </button>
        </div>
      </div>
    </div>
    <div className="bgmicrobeer marginbottom">
       <div id="nuestroMicroContainer">
        <div className="causaStory odd">
          <div className="causaStoryDiv left propositoImage">
          <div className="propositoTituloMicro">
              <h1>
                Nuestra
                <br />
                Recetas
              </h1>
              <div>
                <img  alt="" src={underline} />
              </div>
              <img  alt="" className="arrowPropoMobile" src={arrowMobile} />
            </div>
            <div
              className="causaImagenContainer"

            >
              <div className="pointsleft">
                <div className="marginpoint">
                  <img src={point1} />
                </div>
                <div className="marginline">
                  <img src={line} />
                </div>
                <div className="marginpoint">
                  <img src={point2} />
                </div>
              </div>
              <div>
                <FadeIn img={microImg3} position={'left'} end={false} />
              </div>
            </div>
          </div>
          <div className="causaStoryDiv right propositoText">
            <div className="causatextwidth">
              <p className="causaTextWhiteDesktop">
                El Brewhouse alemán de última generación es donde nuestro equipo de cerveceros crea nuestras cervezas de estación, hay más de 70 recetas que nacieron en este lugar único para nosotros. La inspiración para muchas de ellas vino de personas dentro del equipo de Patagonia, como el caso de la Pine Pale Ale y nuestra querida Vera IPA, entre otras. Celebramos esas historias porque hablan de la gente que forma parte de esta gran familia, y eso es algo que queremos seguir transmitiendo siempre. 
                <br/>
                <br/>
              </p>

              <p className="causaTextMobile">
              En el Salón <mark className="fontBlack"> te esperamos con una carta que incluye entradas, principales, sándwiches y postres, con atención en mesa. Mientras tanto, en el Jardín Cervecero </mark>, te esperamos con una propuesta más descontracturada, con puntos de venta autoservicio y propuestas gastronómicas que salen directo de las brasas, rodeado de naturaleza y con acceso a los miradores del Lago Moreno.
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div id="nuestroPropContainer" className="displaymobile">
      <div className="causaStory odd noMargin ">
        <div className="causaStoryDiv left propositoText">
          <div className="causatextwidth">
            <p className="causaTextDesktop">
              <br />
              <br />
              Las puertas de nuestra Micro están abiertas todos los días para que nuestros invitados conozcan la dedicación con la que hacemos nuestras cervezas. En los demás ambientes también te podés sorprender con experiencias como talleres de home-brewing, noches de bandas en vivo y tours cerveceros. Todo esto en un entorno natural que respetamos con nuestra planta de efluentes con tecnología de tratamiento biológico, única en la Argentina. Nuestro objetivo es invitarte a vivir la mejor experiencia cervecera del mundo desde la Patagonia.
              <br />
              <br />
            </p>

            <p className="causaTextMobile">
              La Patagonia nos inspira, nos permite explorar y conocer, es
              nuestro origen, nuestro hogar. Nuestra microcervecería se ubica en
              el KM 24.7 de Circuito Chico, Bariloche, y es el lugar donde
              nuestro equipo cervecero crea nuevas recetas inspiradas en la
              naturaleza que los rodea. Es en Bariloche donde más fuerte late el
              corazón de Cerveza Patagonia y donde nuestro espíritu explorador
              alcanza su máxima expresión. <mark className="fontBlack">Nuestro sueño es brindar la mejor experiencia cervecera del mundo
              desde la Patagonia, y es por eso que estamos determinados en
              formar parte de su cuidado.</mark>
              
            </p>
          </div>
        </div>
        <div className="causaStoryDiv right propositoImage underlineImage">
          <div>
            <img  alt="" src={underline2} />
          </div>
          <div
            className="causaImagenContainer"
          >
            <FadeIn img={microImg4} position={'right'} end={false} />
          </div>
        </div>
      </div>
      </div>

      <div className="displaynonemobile">
        <img src={gridimages} alt="" className="gridimages"/>
      </div>

    <div id="nuestroMicroContainer">
      <div className="carrouselProposito">
        <Slider {...settings}>
          {carrouselImages.map(image => {
            return (
              <div className="carrouselDiv">
                {<img  alt="" src={image.foto} className="carrouselImage" />}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
    </>
  )
}

export default MicrocerveceriaSection;
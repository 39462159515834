import React from "react"
import underline from "../../images/proposito/underlineAcciones.png"
import circle from "../../images/proposito/circle.png"
import leftFixed from "../../images/microbeer/leftFixedPropMicro.png"
import highlight from "../../images/proposito/highlight.png"
import rightArrow from "../../images/proposito/RightArrow.png"
import leftArrow from "../../images/proposito/LeftArrow.png"
import separatorHeader from "../../images/proposito/separatorHeaderMobile.png"

const ExperienciaHeader = () => {
  return (
    <>
      <div id="nuestrasAccHeader displaynomobile">
        <img alt="" className="fixedPropo" src={leftFixed} />
        <div className="separatorHeader"></div>
      </div>
      <div id="nuestrasAccHeader displaymobile">
        <img alt="" className="fixedPropo" src={leftFixed} />
        <div className="separatorHeader"></div>
      </div>
    </>
  )
}

export default ExperienciaHeader

import React from "react"
import classNames from "classnames"
import HeaderEnd from "../../images/proposito/HeaderEnd.png"
import background from "../../images/proposito/HeaderImage.png"
import MicroBeerVideo from "../MicroBeerVideo"

export const SECTION_MICROCERVECERIA = "microcerveceria"
export const SECTION_EXPERIENCIA = "experiencia"

const Header = ({ handler, section }) => {
  const experienciaBtnClasses = classNames({
    headerButtonText: true,
    selected: section === SECTION_EXPERIENCIA,
  })
  const microcerveceriaBtnClasses = classNames({
    headerButtonText: true,
    selected: section !== SECTION_EXPERIENCIA,
  })

  return (
    
    <div id="headerPropMicroContainer">
      <MicroBeerVideo />
      <div className={"nuestroProposito"}>
          <h1>{""}</h1>
      </div>
      <div className="headerButtonsContainer">
        <div
          className="headerButton"
          onClick={() => handler(SECTION_MICROCERVECERIA)}
        >
          <h1 className={microcerveceriaBtnClasses}>{"Microcervecería"}</h1>
        </div>

        <div className="headerButton" onClick={() => handler(SECTION_EXPERIENCIA)}>
          <h1 className={experienciaBtnClasses}>{"Experiencias"}</h1>
        </div>
      </div>
      <div className="HeaderEndCont">
        <img  alt="" src={HeaderEnd} className="imgHeaderEnd" />
      </div>
    </div>
  )
}

export default Header

import React from 'react'
import ExperienciaHeader from './ExperienciaHeader'
import ExperienciaSection from './ExperienciaSection'

const Experiencia = () => {
    return(
        <>
        <ExperienciaHeader/>
        <ExperienciaSection/>
        </>
    )
}

export default Experiencia
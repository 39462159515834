import React from "react"
import PropTypes from "prop-types"
import Cookies from "js-cookie"
import SEO from "../components/seo"
import Header from "../components/header"
import BeersMenu from "../components/BeersMenu"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import Footer from "../components/Footer"
import BirthConsent from "../components/BirthConsent"
import Microcerveceria from "../components/microcerveceria/Microcerveceria"
import {
  SECTION_PROPOSITO,
  SECTION_ACCIONES,
} from "../components/microcerveceria/Header"

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};

export default class microcerveceria extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      isScrolling: false,
      menuOpen: false,
      menuClass: "slideOut",
      isDark: false,
      width: 1000,
      birthConsent: true,
      section: SECTION_PROPOSITO,
    }
  }

  handleDark = (bool) =>{
    this.setState({
      isDark: bool
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    this.setState({
      width: window.innerWidth,
    })

    if (hasConfirmed()){
      this.handleConsent()
    }
    else {
      this.setState({
        birthConsent: false,
      })
    }
  }

  handleConsent = () =>{
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  handleChangeSection = section => {
    this.setState({ section })
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  onMenuToggle = () =>{
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? 'slideOut' : 'slideIn',
      isDark: !this.state.menuOpen
    })
  };

  onScroll = () => {
    this.setState({ isScrolling: true });

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false });
    }, 200);

    var section = document.getElementsByTagName("SECTION");



    var bScroll = document.scrollingElement.scrollTop;

    for (var i = 0; i < section.length; i++) {

      var sHeight = section[i].offsetHeight;
      var offsets = section[i].offsetTop;


      if (bScroll > offsets && bScroll < offsets + sHeight) {

        if (section[i].className !== 'microbeerHome'){
          this.setState({ isDark: true });
        }
        else if (section[i].className === 'microbeerHome') {
          this.setState({ isDark: false });
        }
      }/* else {
        section[i].className = "section";
      }*/


    }

  };

  render() {
    return (
      <>
        <SEO title="Microcerveceria" />
        {this.state.birthConsent ?
        <>
          <Header
            isScrolling={this.state.isScrolling}
            onMenuToggle={this.onMenuToggle}
            isDark={this.state.menuOpen}
            menuOpen={this.state.menuOpen}
            width={this.state.width}
          />
          <Microcerveceria
            refProp={this.ref}
            section={this.state.section}
            handler={this.handleChangeSection}
            section={this.state.section}
          />
          {
            this.state.width > 767 ?
              <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
              :
              <MenuMobile onMenuToggle={this.onMenuToggle} menuClass={this.state.menuClass} menuOpen={this.state.menuOpen}/>
          }
          <Footer/>
        </> : <BirthConsent handleConsent={this.handleConsent}/>
        }
      </>
    )
  }
}
import React from "react"
import Header from "./Header"
import Bottom from "./Bottom"
import MicrocerveceriaSection from "./MicrocerveceriaSection"
import Experiencia from "./Experiencia"
import {SECTION_EXPERIENCIA} from './Header'


const Microcerveceria = ({section, handler, refProp}) => {
  return (
    <div id="propositoContainer" ref={refProp}>
      <Header handler={handler} section={section} />
      {section !== SECTION_EXPERIENCIA ?
        <MicrocerveceriaSection /> :
        <Experiencia />
      }
      <Bottom handler={handler} section={section} />
    </div>
  );
}

export default Microcerveceria;
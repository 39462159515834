import React, { useRef } from "react"
import underline from "../../images/proposito/underline.png"
import mb5 from "../../images/microbeer/mb5.png"
import mb6 from "../../images/microbeer/mb6.jpeg"
import mb7 from "../../images/microbeer/mb7.png"
import arrowToLeft from "../../images/proposito/arrowToLeft.png"
import arrowMobile from "../../images/proposito/arrowPropositoMobile.png"
import FadeIn from "./utilities/FadeIn"
import microImg3 from "../../images/microbeer/microImg3.png"
import point1 from "../../images/microbeer/point1.png"
import line from "../../images/microbeer/line.png"
import point2 from "../../images/microbeer/point2.png"
import arrowToRight from "../../images/proposito/arrowToRight.png"
import circle from "../../images/proposito/greenPoint.png"
import arrowToDiagRight from "../../images/microbeer/arrowToDiagRight.png"
import arrowToDiagLeft from "../../images/microbeer/arrowToDiagLeft.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"

// Parallax Effect created with hooks

const ExperienciaSection = () => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  return (
    <>
      <div id="nuestroMicroContainer">
        <div className="causaStory odd">
          <div className="causaStoryDiv left propositoImage">
            <div className="causaImagenContainer">
              <FadeIn img={mb5} position={"left"} end={false} />
            </div>
          </div>
          <div className="causaStoryDiv right propositoText">
            <h1 className="microtitle displaynonemobile">Tour cervecero</h1>
            <h1 className="microtitle displaymobile">
              Tour <br /> cervecero
            </h1>
            <img
              alt=""
              src={arrowToRight}
              className="arrowMicro displaymobile"
            />
            <div className="propositoTitulo">
              <div>
                <img alt="" src={underline} />
              </div>
              <img alt="" className="arrowPropoMobile" src={arrowMobile} />
            </div>

            <div className="causatextwidth">
              <p className="causaTextDesktop">
                Combinamos la{" "}
                <span className="highlighted2">exploración y la pasión</span>{" "}
                por la cerveza en una experiencia que incluye{" "}
                <mark className="fontBlack">recorrido</mark> por nuestra
                Microcervecería donde aprenderás acerca del proceso cervecero y
                nuestras instalaciones,{" "}
                <mark className="fontBlack">degustación</mark> de variedades de
                estación y <mark className="fontBlack">maridaje</mark> con
                gastronomía patagónica para aprender a combinar los diferentes
                platos con las cervezas.
                <br />
                <br />
              </p>

              <p className="causaTextMobile">
                Combinamos{" "}
                <span className="highlighted2">la exploración y la pasión</span>
                por la cerveza en una experiencia que incluye recorrido
                <mark className="fontBlack">
                  {" "}
                  por nuestra Microcervecería, donde aprenderás acerca del
                  proceso cervecero y nuestras instalaciones{" "}
                </mark>
                , degustación{" "}
                <mark className="fontBlack">
                  {" "}
                  de variedades de estación y maridaje
                </mark>{" "}
                con gastronomía patagónica para aprender a combinar los
                diferentes platos con las cervezas.
                <br />
                <br />
              </p>
            </div>
            <div className="buttonrefirst displaymobile">
              <button
                onClick={() =>
                  window.open("https://tourcervecero.meitre.com/", "_blank")
                }
                className="reservationButton3 displaymobile"
              >
                RESERVA ACÁ
              </button>
            </div>
          </div>
          <img alt="" className="arrowProp" src={arrowToLeft} />
        </div>
        <div className="buttonre displaynonemobile">
          <button
            onClick={() =>
              window.open("https://tourcervecero.meitre.com/", "_blank")
            }
            className="reservationButton3"
          >
            RESERVA ACÁ
          </button>
        </div>

        <div className="displaymobile">
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer marginPicture">
              <FadeIn img={mb5} position={"left"} end={false} />
            </div>
          </div>
        </div>

        <div className="displaymobile">
          <div className="causaSeparator">
            <div className="widhtimag">
              <img alt="" src={arrowToDiagRight} />
            </div>
            <div />
          </div>
          <div>
            <img alt="" src={circle} />
          </div>
        </div>
      </div>
      <div className="displaynonemobile">
        <div id="nuestroMicroContainer">
          <div className="causaStory odd">
            <div className="causaStoryDiv left propositoImage">
              <div className="propositoTitulo">
                <h1>
                  Experiencia
                  <br />
                  Domos
                </h1>
                <div>
                  <img alt="" src={underline} />
                </div>
                <img alt="" className="arrowPropoMobile" src={arrowMobile} />
              </div>
              <div className="causatextwidth">
                <p className="causaTextDesktop">
                  <mark className="fontBlack">
                    Conectá con la naturaleza y con nuestro Origen.
                  </mark>{" "}
                  Vení a conocer nuestro Refugio Domo Rodeado por Bosques, lagos
                  y montañas,{" "}
                  <mark className="fontBlack">
                    en el corazón de nuestra Microcervecería
                  </mark>
                  , se encuentra un lugar donde vas a estar en contacto con la
                  naturaleza y vas a poder disfrutar de Sus sonidos, sabores, su
                  paz y esa energía única que recorre a este lugar. Una
                  experiencia de maridaje de gastronomía y nuestras variedades,
                  completan la visita.
                  <br />
                  <br />
                </p>
              </div>
              <div className="buttoncenter">
                <button
                  onClick={() =>
                    window.open("https://refugiodomo.meitre.com/", "_blank")
                  }
                  className="reservationButton5 displaynonemobile"
                >
                  RESERVA ACÁ
                </button>
              </div>
            </div>
            <div className="causaStoryDiv right propositoImage">
              <div className="causaImagenContainer">
                <FadeIn img={mb6} position={"right"} end={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="nuestroMicroContainer">
        <div className="displaymobile">
          <div className="causaStory odd">
            <div className="causaStoryDiv right propositoImage">
              <div className="propositoTitulo textalingleft">
                <h1>
                  Experiencia
                  <br />
                  Domos
                </h1>
                <div>
                  <img alt="" src={underline} />
                </div>
              </div>
              <div className="causatextwidth">
                <p className="causaTextMobileRight">
                  Conectá con la naturaleza y con
                  <span className="highlighted2">nuestro Origen.</span>
                  <br />
                  <br />
                  Vení a conocer nuestro Refugio Domo Rodeado por Bosques, lagos
                  y montañas, en el corazón de nuestra Microcervecería, se
                  encuentra un lugar donde vas a estar en contacto con la
                  naturaleza y vas a poder disfrutar de Sus
                  <mark className="fontBlack">
                    sonidos, sabores, su paz y esa energía única que recorre a
                    este lugar
                  </mark>
                  . Una experiencia de maridaje de gastronomía y nuestras
                  variedades, completan la visita.
                </p>
              </div>
              <div className="buttonre">
                <button
                  onClick={() =>
                    window.open(
                      "http://www.experienciapatagonia.com.ar/",
                      "_blank"
                    )
                  }
                  className="reservationButton3 displaymobile"
                >
                  PROXIMAMENTE
                </button>
              </div>
            </div>
          </div>

          <div className="displaymobile">
            <div className="causaStoryDiv right">
              <div className="causaImagenContainer marginPicture">
                <FadeIn img={mb6} position={"center"} end={false} />
              </div>
            </div>
          </div>
        </div>

        <div className="displaymobile">
          <div className="causaSeparator">
            <div className="widhtimagright">
              <img alt="" src={arrowToDiagLeft} />
            </div>
            <div />
          </div>
        </div>

        <div className="causaStory odd">
          <div className="causaStoryDiv left propositoImage">
            <div className="causaImagenContainer">
              <FadeIn img={mb7} position={"left"} end={false} />
            </div>
          </div>
          <div className="causaStoryDiv right propositoText">
            <h1 className="microtitle displaynonemobile">
              Experiencia Patagonia
            </h1>
            <h1 className="microtitle displaymobile">
              Experiencia <br /> Patagonia
            </h1>
            <div className="propositoTitulo">
              <div>
                <img alt="" src={underline} />
              </div>
              <img alt="" className="arrowPropoMobile" src={arrowMobile} />
            </div>

            <div className="causatextwidth">
              <p className="causaTextDesktop">
                Un viaje pensado para vos.
                <br />
                <br />
                Descubri Bariloche de una{" "}
                <span className="highlighted2"> manera diferente</span> con
                nuestra experiencia que incluye alojamiento, traslados,
                excursiones y conocer todo sobre nuestras variedades.
              </p>

              <p className="causaTextMobile">
                Un viaje pensado para vos.
                <br />
                <br />
                Descubri Bariloche de una
                <br />
                <span className="highlighted2"> manera diferente</span> con
                nuestra experiencia que incluye alojamiento, traslados,
                excursiones y conocer todo sobre nuestras variedades.
              </p>
            </div>
            <div className="buttonrefirst displaymobile">
              <button
                onClick={() =>
                  window.open(
                    "http://www.experienciapatagonia.com.ar/",
                    "_blank"
                  )
                }
                className="reservationButton5 displaymobile"
              >
                Más información
              </button>
            </div>
          </div>
        </div>
        <div className="buttonre displaynonemobile">
          <button
            onClick={() =>
              window.open("http://www.experienciapatagonia.com.ar/", "_blank")
            }
            className="reservationButton5"
          >
            Más información
          </button>
        </div>

        <div className="displaymobile">
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer marginPicture">
              <FadeIn img={mb7} position={"left"} end={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExperienciaSection

import React from "react"
import videoMobile from "../videos/Drone2.mp4"
import video from "../videos/Drone1.mp4"

function MicroBeerVideo() {
  return (
    <div className="videowidth">
        <video
          id={"video"}
          className={"hvideo"}
          playsInline
          loop
          muted
          autoPlay
          disablePictureInPicture="true"
        >
          <source src={video} />
        </video>

        <video
          id={"video"}
          className={"hvideomobile"}
          playsInline
          loop
          muted
          autoPlay
          disablePictureInPicture="true"
        >
          <source src={videoMobile} />
        </video>
    </div>
  )
}

export default MicroBeerVideo;

import React, { useState, useEffect, useRef } from "react"
import lataEnd from "../../../images/proposito/lataEnd.png"
import classnames from "classnames"

const FadeIn = ({ img, end, position }) => {
  const [offset, setOffset] = useState(0)
  const reference = useRef(null)
  
  let alignItems;

  switch (position) {
    case "right":
      alignItems = "flex-end"
      break
    case "left":
      alignItems = "flex-start"
      break
    case "center":
      alignItems = "center"
      break
    default:
      alignItems = "flex-end"
  }

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  let offsetTop = reference.current
    ? reference.current.getBoundingClientRect().top
    : 0
  let offsetBot = reference.current
    ? reference.current.getBoundingClientRect().bottom
    : 0
  //console.log(offset, offsetTop)

  const classes = classnames({
    imgFadeIn: true,
    "is-visible": offsetTop < 750 && offsetTop > -150,
  })

  return (
    <div className={classes} ref={reference}>
      <img style={{width:"500px"}}  alt="" src={img} />
      {end === true ? <img  alt="" src={lataEnd} /> : null}
    </div>
  )
}

export default FadeIn
